<template>
  <CRow>
    <CCol col="12">
      <transition name="slide">
        <CCard>
          <CCardBody>
            <h4>
              {{ $t('forms.transl125')}}
            </h4>
            <CButton color="success" @click="createRecord()" class="mb-3 my-5">{{ $t('forms.transl126')}} <font-awesome-icon icon="fas fa-plus" /> </CButton>
            <div class="flex-center position-ref full-height">
              <data-table
                :fetch-url="datatTableUrl"
                :columns="['id', 'email', 'name', 'surname']"
                :headers="{'id': 'ID','email': $t('forms.transl22'),'name': $t('forms.transl23'),'surname': $t('forms.transl24')}"
                :routeName="routeName"
              ></data-table>
            </div>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue';

export default {
  data() {
    return {
      datatTableUrl: '',
      routeName: 'administrators'
    }
  },
  created: function () {
    this.datatTableUrl = Vue.prototype.$apiAdress + '/api/' + this.routeName + '?token=' + localStorage.getItem("api_token");
  },
  methods: {
    createRecord() {
      this.$router.push({path: this.routeName + '/create'});
    },
  }
}
</script>
